import React, { useCallback } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import RegexAdaptRoute from './components/AdaptRoutes/RegexAdaptRoute';
import ProtectedRoute from './components/ProtectedRouteComponents/ProtectedRoute';
import ProtectedUserRoute from './components/ProtectedRouteComponents/ProtectedUserRoute';
import ProtectedAdminRoute from './components/ProtectedRouteComponents/ProtectedAdminRoute';
import companiesHelper from './helpers/companiesHelper';
import Login from './pages/Login/Login';
import Activate from './pages/Activate/Activate';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import AdminDashboard from './pages/Admin/Dashboard/Dashboard';
import AdminCompanies from './pages/Admin/Companies/Companies';
import AdminProducts from './pages/Admin/Products/Products';
import AdminUsers from './pages/Admin/Users/Users';
import AdminUpdates from './pages/Admin/Updates/Updates';
import Settings from './pages/Settings/Settings';
import Logout from './pages/Logout/Logout';
import AlertsPage from './pages/Alerts/AlertsPage';
import AdminAlertsPage from './pages/Admin/Alerts/AlertsPage';
import Updates from './pages/Updates/Updates';
import Dashboard from './pages/Dashboard/Dashboard';
import Products from './pages/Products/Products';
import AddEditProduct from './pages/Admin/Products/AddEditProduct/AddEditProduct';
import Layout from './components/Layout/Layout';
import ProductOverview from './pages/ProductOverview/ProductOverview';
import AdminProductOverview from './pages/Admin/ProductOverview/ProductOverview';
import BatchesOverview from './pages/BatchesOverview/BatchesOverview';
import AdminBatchesCampaign from './pages/Admin/BatchesOverview/BatchesOverview';
import StepsTab from './pages/ProductOverview/StepsTab/StepsTab';
import StatusTab from './pages/ProductOverview/StatusTab/StatusTab';
import AdminStatusTab from './pages/Admin/ProductOverview/StatusTab/StatusTab';
import DetailTab from './pages/ProductOverview/DetailTab/DetailTab';
import AdminDetailTab from './pages/Admin/ProductOverview/DetailTab/DetailTab';
import AlertsTab from './pages/ProductOverview/AlertsTab/AlertsTab';
import AdminAlertsTab from './pages/Admin/ProductOverview/AlertsTab/AlertsTab';
import AdminUpdatesTab from './pages/Admin/ProductOverview/UpdatesTab/UpdatesTab';
import UpdatesTab from './pages/ProductOverview/UpdatesTab/UpdatesTab';
import AdminStepsTab from './pages/Admin/ProductOverview/StepTab/StepTab';
import AccountTab from './pages/Settings/AccountTab/AccountTab';
import SecurityTab from './pages/Settings/SecurityTab/SecurityTab';
import CompanyOverview from './pages/Admin/CompanyOverview/CompanyOverview';
import CompanyStatusTab from './pages/Admin/CompanyOverview/CompanyStatusTab/CompanyStatusTab';
import CompanyDetailTab from './pages/Admin/CompanyOverview/CompanyDetailsTab/CompanyDetailsTab';
import CompanyUsersTab from './pages/Admin/CompanyOverview/CompanyUsersTab/CompanyUsersTab';
import CompanyProductsTab from './pages/Admin/CompanyOverview/CompanyProductsTab/CompanyProductsTab';
import AdminSearch from './pages/Admin/Search/Search';
import Search from './pages/Search/Search';
import UserType from './models/Account/UserType';
import UserPermission from './models/Account/UserPermission';
// Temporary disalbe SFTP related route FileManagement
// import FileManagement from './pages/Admin/FileManagement/FileManagement';
import Maintenance from './pages/Maintenance/Maintenance';
import Terms from './pages/Terms/Page/Terms';
import Privacy from './pages/Privacy/Page/Privacy';
import AppGallery from './pages/AppGallery/AppGallery';
import ProcessTab from './pages/AppGallery/ProcessTab/ProcessTab';
import AppsTab from './pages/Settings/AppsTab/AppsTab';
import CategoryTab from './pages/AppGallery/CategoryTab/CategoryTab';
import ListTab from './pages/AppGallery/ListTab/ListTab';
import ExternalFrame from './pages/ExternalFrame/ExternalFrame';
import NavigationType from './models/AppGallery/Module/NavigationType';
import Messages from './pages/Messages/Messages';
import AdminToUserProductsRoute from './components/AdaptRoutes/AdminToUserProductsRoute';
import CompanyDetailsTab from './pages/Settings/CompanyDetailsTab/CompanyDetailsTab';
// Temporary disalbe SFTP related route Upload
// import FileUploadList from './pages/FileUpload/FileUploadList';
import Manufacturer from './pages/Manufacturer/Manufacturer';
import SynthesisStepOverview from './pages/SynthesisStepOverview/SynthesisStepOverview';
import Users from './pages/OrganizationAdmin/Users/Users';
import AppManagement from './components/AppManagement/AppManagement';
import AdminToSeniorManagerUsersRoute from './components/AdaptRoutes/AdminToSeniorManagerUsersRoute';
import useAppNavigation from './hooks/appGallery/useAppNavigation';
import Spinner from './components/Spinner/Spinner';
import { useAppContext } from './context/useAppContext';
import CampaignsTab from './pages/ProductOverview/CampaignsTab/CampaignsTab';
import AdminCampaignsTab from './pages/Admin/ProductOverview/CampaignsTab/CampaignsTab';
import SynthesisStepDetailsTab from './pages/SynthesisStepOverview/DetailsTab/DetailsTab';
import SynthesisStepCampaignsTab from './pages/SynthesisStepOverview/CampaignsTab/CampaignsTab';

const AppRoutes: React.FC = () => {
  const {
    state: { user, authorized },
  } = useAppContext();

  const {
    isLoading: isLoadingNavigation,
    navigation,
    isTrackersAvailable,
    isUsersAvailable,
    isManufacturerFacilitiesAvailable,
    isAppManagementAvailable,
  } = useAppNavigation();

  const getLoggedNav = () => {
    return <Navigate to="/" />;
  };

  const getAdminRoutes = useCallback(
    () => (
      <Route path="" element={<ProtectedAdminRoute />}>
        <Route path="dashboard">
          <Route path="search/:searchTerm" element={<AdminSearch />} />
          <Route index element={<AdminDashboard />} />
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route path="companies/:id" element={<CompanyOverview />}>
          <Route path="dashboard" element={<CompanyStatusTab />} />
          <Route path="details" element={<CompanyDetailTab />} />
          <Route path="users" element={<CompanyUsersTab />} />
          <Route path="products" element={<CompanyProductsTab />} />
          <Route
            path="alerts"
            element={
              <RegexAdaptRoute
                regex={/\/companies\/([\da-f]+)\/alerts\/?.*/g}
                replace="/alerts?companyId=$1"
                fallback="/dashboard"
              />
            }
          />
          <Route
            path="updates"
            element={
              <RegexAdaptRoute
                regex={/\/companies\/([\da-f]+)\/updates\/?.*/g}
                replace="/updates?companyId=$1"
                fallback="/dashboard"
              />
            }
          />
          <Route index element={<Navigate to="details" replace />} />
          <Route path="*" element={<Navigate to="details" replace />} />
        </Route>
        <Route />
        <Route path="companies" element={<AdminCompanies />} />
        <Route path="products">
          <Route path="" element={<AdminProducts />} />
          <Route path="add" element={<AddEditProduct />} />
          <Route path=":id">
            <Route path="step/:stepId" element={<SynthesisStepOverview />}>
              <Route path="details" element={<SynthesisStepDetailsTab />} />
              <Route path="campaigns" element={<SynthesisStepCampaignsTab />} />
              <Route index element={<Navigate to="details" replace />} />
              <Route path="*" element={<Navigate to="details" replace />} />
            </Route>
            <Route path="batchesOverview" element={<AdminBatchesCampaign />} />
            <Route path="edit" element={<AddEditProduct />} />
            <Route path="" element={<AdminProductOverview />}>
              <Route path="status" element={<AdminStatusTab />} />
              <Route path="details" element={<AdminDetailTab />} />
              <Route path="alerts" element={<AdminAlertsTab />} />
              <Route path="steps" element={<AdminStepsTab />} />
              <Route path="campaigns" element={<AdminCampaignsTab />} />
              <Route path="updates" element={<AdminUpdatesTab />} />
              <Route index element={<Navigate to="details" replace />} />
              <Route path="*" element={<Navigate to="details" replace />} />
            </Route>
          </Route>
        </Route>
        <Route path="users" element={<AdminUsers />} />
        <Route path="alerts" element={<AdminAlertsPage />} />
        <Route path="updates" element={<AdminUpdates />} />
        {/* Temporary disalbe SFTP related route FileManagement */}
        {/* <Route path="fileManagement" element={<FileManagement />} /> */}
        <Route path="appManagement" element={<AppManagement />} />
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="*" element={<Navigate to="dashboard" replace />} />
      </Route>
    ),
    [],
  );

  const getUserRoutes = useCallback(() => {
    const companyId = user!.companyId!;
    const dashboardUrl = companiesHelper.getDashboardUrl(companyId);
    return (
      <>
        <Route path="companies/:companyId" element={<ProtectedUserRoute />}>
          <Route path="dashboard">
            <Route
              path="search/:searchTerm"
              element={
                <Search
                  companyId={companyId}
                  isUsersAvailable={isUsersAvailable}
                  isManufacturerFacilitiesAvailable={isManufacturerFacilitiesAvailable}
                />
              }
            />
            <Route
              index
              element={isTrackersAvailable ? <Dashboard companyId={companyId} /> : <Navigate to="/appGallery" />}
            />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>
          {isTrackersAvailable && (
            <>
              <Route path="products">
                <Route path="" element={<Products companyId={companyId} />} />
              </Route>
              <Route path="updates" element={<Updates companyId={companyId} />} />
              <Route path="alerts" element={<AlertsPage companyId={companyId} />} />
              <Route index element={<Navigate to="dashboard" replace />} />
            </>
          )}
          {isManufacturerFacilitiesAvailable && <Route path="manufacturer" element={<Manufacturer />} />}
          {isUsersAvailable && <Route path="users" element={<Users companyId={companyId} />} />}
          {isAppManagementAvailable && <Route path="appManagement" element={<AppManagement companyId={companyId} />} />}
          <Route path="*" element={<Navigate to="dashboard" replace />} />
        </Route>
        {isTrackersAvailable && (
          <>
            <Route path="products">
              <Route path=":id">
                <Route path="step/:stepId" element={<SynthesisStepOverview />}>
                  <Route path="details" element={<SynthesisStepDetailsTab />} />
                  <Route path="campaigns" element={<SynthesisStepCampaignsTab companyId={companyId} />} />
                  <Route index element={<Navigate to="details" replace />} />
                  <Route path="*" element={<Navigate to="details" replace />} />
                </Route>
                <Route path="batchesOverview" element={<BatchesOverview companyId={companyId} />} />
                <Route path="" element={<ProductOverview />}>
                  <Route path="status" element={<StatusTab companyId={companyId} />} />
                  <Route path="details" element={<DetailTab />} />
                  <Route path="steps" element={<StepsTab />} />
                  <Route path="campaigns" element={<CampaignsTab />} />
                  <Route path="alerts" element={<AlertsTab companyId={companyId} />} />
                  <Route path="updates" element={<UpdatesTab companyId={companyId} />} />
                  <Route index element={<Navigate to="details" replace />} />
                  <Route path="*" element={<Navigate to="details" replace />} />
                </Route>
              </Route>
              <Route index element={<AdminToUserProductsRoute companyId={companyId} fallback={dashboardUrl} />} />
              <Route path="*" element={<Navigate to={dashboardUrl} replace />} />
            </Route>
            <Route
              path="updates"
              element={
                <RegexAdaptRoute
                  regex={/^.*companyId=([\da-f]+).*$/gi}
                  replace="/companies/$1/updates"
                  fallback={dashboardUrl}
                />
              }
            />
            <Route
              path="alerts"
              element={
                <RegexAdaptRoute
                  regex={/^.*companyId=([\da-fA-F]+).*$/gi}
                  replace="/companies/$1/alerts"
                  fallback={dashboardUrl}
                />
              }
            />
          </>
        )}
        <Route path="users">
          <Route index element={<AdminToSeniorManagerUsersRoute companyId={companyId} fallback={dashboardUrl} />} />
          <Route path="*" element={<Navigate to={dashboardUrl} replace />} />
        </Route>
        {/* Temporary disalbe SFTP related route Upload */}
        {/* {isManufacturerFacilitiesAvailable && <Route path="upload" element={<FileUploadList />} />} */}
        <Route index element={<Navigate to={dashboardUrl} replace />} />
        <Route path="*" element={<Navigate to={dashboardUrl} replace />} />
      </>
    );
  }, [user, isTrackersAvailable]);

  if (isLoadingNavigation) {
    return (
      <>
        <Routes>
          <Route path="login" element={authorized ? getLoggedNav() : <Login />} />
        </Routes>
        <Spinner />
      </>
    );
  }

  return (
    <Routes>
      <Route path="terms" element={<Terms />} />
      <Route path="privacyPolicy" element={<Privacy />} />
      <Route path="login" element={authorized ? getLoggedNav() : <Login />} />
      <Route path="activate" element={authorized ? getLoggedNav() : <Activate />} />
      <Route path="reset-password" element={authorized ? getLoggedNav() : <ResetPassword />} />
      <Route path="logout" element={<Logout />} />
      <Route path="maintenance" element={<Maintenance />} />
      <Route path="*" element={<ProtectedRoute />}>
        <Route path="" element={<Layout />}>
          <Route path="external">
            {navigation
              ?.filter((x) => x.type === NavigationType.Frame)
              .map((item) => (
                <Route key={item.name} path={item.name} element={<ExternalFrame src={item.path} title={item.name} />} />
              ))}
          </Route>
          {user?.type === UserType.Admin && getAdminRoutes()}
          {user?.type && user.type !== UserType.Admin && (
            <>
              {getUserRoutes()}
              <Route path="appGallery" element={<AppGallery />}>
                <Route path="process" element={<ProcessTab />} />
                <Route path="category" element={<CategoryTab />} />
                <Route path="list" element={<ListTab />} />
                <Route index element={<Navigate to="process" replace />} />
                <Route path="*" element={<Navigate to="process" replace />} />
              </Route>
            </>
          )}
          <Route path="messages" element={<Messages />} />
          <Route path="settings" element={<Settings />}>
            <Route path="account" element={<AccountTab />} />
            <Route path="security" element={<SecurityTab />} />
            {user?.permissions.some((x) => x === UserPermission.MyAppsAccess) && (
              <Route path="apps" element={<AppsTab />} />
            )}
            <Route path="companyDetails" element={<CompanyDetailsTab />} />
            <Route index element={<Navigate to="account" replace />} />
            <Route path="*" element={<Navigate to="account" replace />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
